import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import "./TwitterLogo.scss";

class TwitterLogo extends Component {
  startAnimation() {
    let tl = anime.timeline({
      easing: "easeOutExpo",
      direction: "alternate",
      loop: true,
      delay: 5000,
    });

    // Add children
    tl.add({
      targets: ".animated-twitter-logo .twitter-path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 3000,
      delay: 250,
    });

    tl.add({
      targets: ".animated-twitter-logo .twitter-path",
      fill: "#000",
      delay: 250,
    });

    return tl;
  }

  componentDidMount() {
    this.startAnimation().play();
  }

  render() {
    return (
      <div className="animated-twitter-logo">
        <svg viewBox="0 0 500 500">
          <path
            className="twitter-path"
            d="m 343.62677,57.59282 c -54.94858,0.39776 -99.26573,41.56271 -99.26599,92.2054 l 0.0488,2.89664 c 0.32102,8.65961 1.96481,17.2343 4.88287,25.47104 C 223.44749,179.59998 119.01569,166.87527 40.811917,73.94566 40.361751,73.4955 -0.828736,149.57264 71.87257,205.61793 c 0.2611,0.20127 0.52493,0.39417 0.78683,0.59186 -26.352555,-0.69654 -46.027844,-11.39512 -46.027844,-11.39512 5.40195,87.55672 80.354204,98.13448 80.354204,98.13448 0,0 -12.97132,9.53189 -45.241017,2.47712 24.712437,61.30354 76.760307,68.07208 90.435037,68.78501 C 95.75737,414.58515 7.686695,406.50341 7.686695,406.50341 378.34704,577.10315 461.14991,183.96883 444.41246,154.08053 l -0.18104,0.22455 c 26.8315,-21.43619 35.97487,-33.07215 49.21871,-51.9987 -28.70448,11.82691 -52.83549,14.17626 -55.71875,14.36487 -0.0289,-0.079 -0.0579,-0.15786 -0.0871,-0.23674 2.48616,-1.42607 35.36901,-20.82454 42.30085,-53.96752 0,0 -29.41764,18.12938 -61.93157,25.02713 C 398.91424,68.29056 371.89223,57.4285 343.6268,57.59282 Z"
          />
        </svg>
      </div>
    );
  }
}

export default TwitterLogo;
