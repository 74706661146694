import React, { Component } from "react";
import { Route } from "react-router-dom";
import DrupalConnectionAPI from "./utils/DrupalConnectionAPI";
import Auth from "./utils/Auth";
import Header from "./layout/Header/Header";
import LaunchCard from "./components/LaunchCard/LaunchCard";
import Footer from "./layout/Footer/Footer";
import Welcome from "./components/Welcome/Welcome";
import Resume from "./Resume";
import Callback from "./Callback";
import AuthContext from "./AuthContext";
import "./App.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: new Auth(this.props.history),
      tokenRenewalComplete: false,
      intro: {
        name: "Michael C. Breuer",
        description:
          "Self-exiled web developer, living in the Philippines, spending his days studying, reading and riding his motorbike. Loves JavaScript, synth-wave, and BBQ.",
        job: "Front End Web Developer",
        photo: "../images/profile-sikatuna.jpg",
      },
      social: [],
    };
  }

  requestContent() {
    const makeDrupalConnection = new DrupalConnectionAPI();

    makeDrupalConnection.getSingleNode(2).then((data) => {
      if (typeof data !== "undefined") {
        this.setState({
          intro: {
            name: data.title[0].value,
            description: data.field_landing_page_description[0].value,
            job: data.field_landing_page_job_title[0].value,
            photo: data.field_landing_page_photo[0].url,
          },
          social: data.field_landing_page_social_media,
        });
      }
    });
  }

  componentDidMount() {
    this.requestContent();
    this.state.auth.renewToken(() =>
      this.setState({ tokenRenewalComplete: true })
    );
  }

  render() {
    const { auth } = this.state;
    // Show loading message until the token renewal check is completed.
    if (!this.state.tokenRenewalComplete) return "Loading...";
    return (
      <AuthContext.Provider value={auth}>
        <Header auth={auth} />
        <div className="wrapper">
          <Welcome content={this.state.intro} />
          <main>
            <Route
              path="/callback"
              exact
              component={(props) => <Callback auth={auth} {...props} />}
            />

            {/* <Route
              path="/callback"
              exact
              component={(props) => <Callback auth={auth} {...props} />}
            /> */}
            {auth.isAuthenticated() ? <Resume /> : ""}
          </main>
        </div>
        <section className="wrapper">
          <div className="container">
            <div className="columns">
              {this.state.social.map((val, index) => (
                <LaunchCard
                  description={val.title}
                  link={val.uri}
                  key={index}
                />
              ))}
            </div>
          </div>
        </section>
        <Footer text="This site was built with ReactJS, Bulma, FontAwesome, AnimeJS and" />
      </AuthContext.Provider>
    );
  }
}

export default App;
