import axios from "axios";
import { Promise } from "q";
import { DrupalSite } from "./drupalConfig";

class DrupalConnectionAPI {
  /**
   * Request a single node by id and return a JSON object
   * @param {number} id
   */
  async getSingleNode(id) {
    try {
      let url = `${DrupalSite}/node/${id}?_format=json`;
      return await axios.get(url).then((response) => response.data);
    } catch (error) {
      if (error.message === "Network Error") {
        console.log("Server is down");
      } else {
        console.log("Error: ", error);
      }
    }
  }

  /**
   * Make a request to a specific endpoint (ie. View REST Export)
   * and return data based on the id
   * @param {number} id
   * @param {string} endpoint
   */
  async getSingleNodeFromSpecificEndpoint(id, endpoint) {
    try {
      let url = `${DrupalSite}/${endpoint}/${id}?_format=json`;
      return await axios.get(url).then((response) => response.data);
    } catch (error) {
      console.error(error);
    }
  }

  /**
   *
   * @param user
   * @returns {Promise<unknown>}
   */
  async login(user) {
    let data = this.getToken(user);
    let config = this.getConfig;
    let url = `${DrupalSite}/user/login?_format=json`;

    return await axios
      .post(url, data, config)
      .then((res) => {
        localStorage.setItem("loggedIn", true);
        localStorage.setItem("csrf_token", res.data.csrf_token);
        localStorage.setItem("username", user.name);
        localStorage.setItem(
          "auth_token",
          btoa(user.username + ":" + user.pass)
        );
        // window.location.href = "/";
        Promise.resolve(res.data);
        console.log(res.data);
      })
      .catch(({ error }) => {
        localStorage.setItem("loggedIn", false);
        Promise.reject(error);
      });
  }

  getToken(user) {
    return JSON.stringify({
      name: user.name,
      pass: user.pass,
    });
  }

  getConfig() {
    return JSON.stringify({
      headers: {
        "Content-Type": "application/json",
      },
    });
  }
}

export default DrupalConnectionAPI;
