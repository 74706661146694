import React from "react";
import { Link } from "react-router-dom";
import MCBLogo from "../../components/AnimatedLogo/MCB/MCBLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Header.scss";

const Header = (props) => {
  const { isAuthenticated, login, logout } = props.auth;
  return (
    <>
      <header>
        <div className="container">
          <div className="columns is-vcentered is-mobile">
            <div className="column name">
              <Link to="/">
                <MCBLogo />
              </Link>
            </div>
            <div className="column has-text-right tagline">
              <button
                className="button is-link is-inverted is-outlined"
                onClick={isAuthenticated() ? logout : login}
              >
                {isAuthenticated() ? (
                  <span>
                    <FontAwesomeIcon icon="sign-out-alt" /> Logout
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon icon="lock" /> Log In
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
