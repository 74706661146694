import React from "react";
import AnimatedLogo from "../AnimatedLogo/AnimatedLogo";
import "./LaunchCard.scss";

const LaunchCard = ({ description, link }) => {
  const clickhandler = () => {
    window.location.assign(link);
  };

  const getIconFromLink = (url) => {
    const options = [
      "github",
      "twitter",
      "linkedin",
      "facebook",
      "instagram",
      "pinterest",
      "drupal",
      "reddit",
      "codepen",
    ];
    const icon = options.filter(function (item) {
      return url.indexOf(item) !== -1;
    });
    return icon[0] || "default";
  };

  return (
    <div className="column has-text-centered">
      <div className="card" onClick={clickhandler}>
        <div className="card-image">
          <div style={{ width: "50%", margin: "0 auto" }}>
            <AnimatedLogo icon={getIconFromLink(link)} />
          </div>
        </div>
        <div className="card-content">
          <div className="content">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default LaunchCard;
