import React, { Component } from "react";

// class Callback extends Component {
//   constructor(props) {
//     super(props);
//   }

//   // componentDidMount() {
//   //   // handle authentication
//   //   if (/access_token|id_token|error/.test(this.props.location.hash)) {
//   //     this.props.auth.handleAuthentication();
//   //   } else {
//   //     throw new Error("Invalid Callback URL");
//   //   }
//   // }
//   render() {
//     return <h1>This is the Callback!</h1>;
//   }
// }

const Callback = (props) => {
  // handle authentication
  if (/access_token|id_token|error/.test(props.location.hash)) {
    props.auth.handleAuthentication();
  } else {
    throw new Error("Invalid Callback URL");
  }
  return <h1>This is the Callback!</h1>;
};

export default Callback;
