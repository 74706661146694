import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GithubLogo from "./Github/GithubLogo";
import LinkedinLogo from "./Linkedin/LinkedinLogo";
import TwitterLogo from "./Twitter/TwitterLogo";
import CodepenLogo from "./Codepen/CodepenLogo";

const options = {
  github: <GithubLogo />,
  linkedin: <LinkedinLogo />,
  twitter: <TwitterLogo />,
  codepen: <CodepenLogo />,
  default: <FontAwesomeIcon icon="user" size="10x" />,
};

const AnimatedLogo = (props) => {
  return options[props.icon];
};

export default AnimatedLogo;
