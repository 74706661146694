import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import "./LinkedinLogo.scss";

class LinkedinLogo extends Component {
  startAnimation() {
    // Create a timeline with default parameters
    let tl = anime.timeline({
      easing: "easeOutExpo",
      direction: "alternate",
      loop: true,
      delay: 1000,
    });

    // Add children
    tl.add({
      targets: ".animated-linkedin-logo .linkedin-outline",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      delay: 250,
    });

    tl.add({
      targets: ".animated-linkedin-logo .letter",
      strokeDashoffset: [anime.setDashoffset, 0],
      delay: function (el, i, l) {
        return i * 250;
      },
    });

    tl.add({
      targets: ".animated-linkedin-logo .letter",
      delay: function (el, i, l) {
        return i * 250;
      },
      fill: "#fff",
    });

    tl.add({
      targets: ".animated-linkedin-logo .linkedin-outline",
      fill: "#000",
      duration: 1500,
      delay: 250,
    });

    return tl;
  }

  componentDidMount() {
    this.startAnimation().play();
  }

  render() {
    return (
      <div className="animated-linkedin-logo">
        <svg viewBox="0 0 800 800">
          <g>
            <rect
              className="linkedin-outline"
              width="785.09155"
              height="785.09155"
              x="8.1166668"
              y="9.9576454"
              ry="67.706238"
            />
            <rect
              className="letter"
              width="113.38756"
              height="371.68463"
              x="127.53619"
              y="305.13052"
              ry="5.7537818e-06"
            />
            <path
              className="letter"
              d="m 115.5284,187.19746 a 67.727219,64.697083 0 0 1 67.19529,-64.69508 67.727219,64.697083 0 0 1 68.25079,63.67887 67.727219,64.697083 0 0 1 -66.12323,65.69534 67.727219,64.697083 0 0 1 -69.28943,-62.64694"
            />
            <path
              className="letter"
              d="m 530.70508,294.08807 c -55.15157,-0.20891 -98.64362,53.14981 -104.91151,61.03339 v -48.10038 l -111.11388,0.25254 v 371.68359 h 113.38672 l 2.02471,-173.7598 c -0.11098,-159.81977 134.3822,-130.31691 131.0163,-4.94137 V 678.95721 H 674.49609 V 500.25604 c -1.06754,-55.34717 0.75762,-73.5487 -10.92382,-123.9707 -11.83935,-51.10361 -68.57143,-79.99972 -130,-82.14258 -0.95983,-0.0335 -1.9155,-0.0511 -2.86719,-0.0547 z"
            />
          </g>
        </svg>
      </div>
    );
  }
}

export default LinkedinLogo;
