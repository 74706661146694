import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import "./MCBLogo.scss";

class MCBLogo extends Component {
  startAnimation() {
    let tl = anime.timeline({
      easing: "easeOutExpo",
      direction: "alternate",
      loop: true,
      duration: 3000,
    });

    // Add children
    tl.add({
      targets: ".mcb-animated-logo #red path",
      strokeDashoffset: [anime.setDashoffset, 0],
      delay: function (el, i, l) {
        return i * 500;
      },
    });
    tl.add({
      targets: ".mcb-animated-logo #bw path",
      strokeDashoffset: [anime.setDashoffset, 0],
      delay: function (el, i, l) {
        return i * 125;
      },
    });
    tl.add({
      targets: ".mcb-animated-logo #red path",
      fill: "#d40000",
      delay: function (el, i, l) {
        return i * 250;
      },
      opacity: 1,
    });

    return tl;
  }

  componentDidMount() {
    this.startAnimation().play();
  }

  render() {
    return (
      <div className="mcb-animated-logo">
        <svg viewBox="0 0 424 160">
          <g transform="translate(-39.328478,-149.5287)">
            <g id="red" transform="translate(-8.01282,-6.01373)">
              <path d="m 217.20026,232.57991 v 0 0 l -17.6625,70.23197 h -34.27989 l 17.558,-70.23197 q 0.41804,-2.71732 -1.14964,-4.70302 -1.67219,-2.09025 -4.49401,-2.09025 h -12.64593 v 0 h -2.29926 q -0.1045,3.34437 -0.94061,6.79327 l -17.66251,70.23197 h -34.27988 l 17.55798,-70.23197 q 0.41805,-2.71732 -1.14962,-4.70302 -1.67219,-2.09025 -4.49401,-2.09025 H 106.41769 L 87.814579,302.81188 H 53.430176 L 79.244608,196.62785 h 31.144532 v -0.10465 h 23.51517 q 20.06628,0 27.2776,20.48433 l 4.80755,-20.37979 h 11.80984 l 0.10449,-0.10465 h 11.80984 q 14.21362,0 22.47005,10.86924 8.15193,10.76472 5.01657,25.18735 v 0 z" />
              <path d="m 320.59043,302.81188 v 0 0 l 7.31581,-30.30842 h -39.81897 v 0 h -15.99033 q -21.52945,-0.31356 -14.42265,-24.66481 2.40377,-8.88353 10.13764,-15.04971 7.73391,-6.58424 20.27534,-7.0023 v 0 h 0.10465 16.8264 v 0 h 34.17534 l 7.00233,-29.0543 h -60.61686 q -37.83331,2.29927 -54.55521,33.75733 -12.43691,27.59114 -3.76243,49.22511 3.97145,10.03313 11.70533,15.88579 7.83839,5.85266 22.99262,6.79326 z" />
              <path d="m 337.99421,303.01806 v 0 0 h 60.6169 q 37.83328,-2.29927 54.55517,-33.75733 12.43694,-27.48661 3.76243,-49.2251 -3.97145,-10.03314 -11.80981,-15.8858 -7.73391,-5.85265 -22.88812,-6.79326 l -24.24675,-0.10464 8.04743,-35.32502 H 372.69215 L 347.4003,264.13954 h 33.33928 l 10.03314,-36.89271 h 5.3301 v 0 h 15.99033 q 21.52942,0.31357 14.42263,24.6648 -2.50829,8.88351 -10.13766,15.04972 -7.73387,6.58424 -20.2753,7.00229 v 0 h -0.10465 -16.82644 v 0 h -34.17538 z" />
            </g>
            <g id="bw">
              <path d="m 99.773947,186.64522 v 0.10424 H 68.629053 L 42.81525,292.93273 h 10.512722 l 23.902406,-98.31741 h 31.144892 v -0.10619 h 23.515 c 5.36388,0 10.02696,1.11797 14.01304,3.3135 -5.20524,-7.4439 -12.73771,-11.17741 -22.61436,-11.17741 z m 67.514693,0 -0.10422,0.10424 h -11.81061 l -3.66352,15.52524 c 3.11797,3.23256 5.60926,7.46533 7.4588,12.7191 l 4.80606,-20.38045 h 11.81059 l 0.10422,-0.10422 h 11.8106 c 5.38779,0 10.13139,1.17538 14.23525,3.51801 -0.12402,-0.17067 -0.23951,-0.34394 -0.36773,-0.51325 -5.50429,-7.24617 -12.99507,-10.86867 -22.47081,-10.86867 z m -51.27169,37.12886 -17.289159,69.15865 h 10.569759 l 15.59013,-62.36647 c 0.27871,-1.81155 -0.10329,-3.37802 -1.1484,-4.70183 -1.11479,-1.39349 -2.61414,-2.09035 -4.49536,-2.09035 z m 55.91452,0 -17.28917,69.15865 h 10.56778 l 15.59212,-62.36647 c 0.27869,-1.81155 -0.10527,-3.37802 -1.15039,-4.70183 -1.11479,-1.39349 -2.61216,-2.09035 -4.49337,-2.09035 z" />
              <path d="m 277.52481,186.66488 c -25.22219,1.53287 -43.40774,12.78433 -54.55567,33.75638 -8.29127,18.39409 -9.54483,34.80194 -3.76185,49.22457 2.319,5.85852 5.6009,10.64656 9.84412,14.36701 -1.13325,-1.88836 -2.14729,-3.90995 -3.01262,-6.09605 -5.78298,-14.42264 -4.52942,-30.83048 3.76184,-49.22457 11.14794,-20.97206 29.33349,-32.22549 54.55569,-33.75836 h 51.79081 l 1.99401,-8.26898 z m -22.40199,74.13186 c 1.47431,6.47293 6.72252,9.77752 15.75143,9.90903 h 15.99131 30.98951 l 1.99598,-8.27095 h -39.81897 -15.98937 c -3.56164,-0.0518 -6.53379,-0.59942 -8.91989,-1.63808 z" />
              <path d="m 361.76039,153.66562 -25.29072,102.21297 h 9.74976 l 24.02824,-97.10604 h 23.68805 l 1.16417,-5.10693 z m 34.93233,35.36691 -1.15435,5.06362 24.24655,0.10617 c 6.41943,0.39847 11.83661,1.45917 16.25872,3.17979 -0.59972,-0.51814 -1.21809,-1.01603 -1.85632,-1.49257 -5.15593,-3.90178 -12.78489,-6.16706 -22.8877,-6.79415 z m 18.42381,35.62644 c 2.80743,4.10467 2.96554,10.43441 0.46802,18.99213 -1.67218,5.92237 -5.05087,10.93858 -10.13713,15.04938 -5.1559,4.3895 -11.91531,6.7239 -20.27625,7.0026 h -0.10427 -16.82704 -34.17523 l -7.00256,29.05453 h 9.71828 l 5.77157,-23.94959 h 34.17523 16.82506 0.10617 c 8.36095,-0.27855 15.11833,-2.61115 20.27427,-7.00061 5.08622,-4.11084 8.46491,-9.12903 10.13713,-15.05132 4.07347,-13.95762 1.08517,-21.98531 -8.95333,-24.09712 z" />
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default MCBLogo;
