import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.scss";

const Footer = ({ text }) => {
  return (
    <footer className="footer">
      <div className="content has-text-centered has-text-white-ter">
        <p>
          {text}&nbsp;
          <FontAwesomeIcon icon="heart" />
        </p>
      </div>
    </footer>
  );
};

export default Footer;
