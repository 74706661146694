import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Chart.scss";

const Chart = () => {
  return (
    <div id="skills-chart">
      <h3 className="title">
        <FontAwesomeIcon icon="code" /> Skills
      </h3>
      <ul className="chart">
        <li>
          <h4>Front End Development</h4>
          <progress
            className="progress is-success"
            value="85"
            max="100"
          ></progress>
        </li>
        <li>
          <h4>Back End Development</h4>
          <progress
            className="progress is-success"
            value="70"
            max="100"
          ></progress>
        </li>
        <li>
          <h4>Documentation</h4>
          <progress
            className="progress is-success"
            value="80"
            max="100"
          ></progress>
        </li>
        <li>
          <h4>Interpersonal</h4>
          <progress
            className="progress is-success"
            value="95"
            max="100"
          ></progress>
        </li>
      </ul>
    </div>
  );
};

export default Chart;
