import React, { Component } from "react";
import DrupalConnectionAPI from "./utils/DrupalConnectionAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Chart from "./components/Chart/Chart";
import "./Resume.scss";

class Resume extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: {
        name: "Title is missing",
        summary: "Summary is missing",
        introduction: "Introduction is missing",
        description: "Description is missing.",
        downloads: [],
      },
    };
  }

  requestContent() {
    const makeDrupalConnection = new DrupalConnectionAPI();

    makeDrupalConnection.getSingleNode(3).then((data) => {
      if (typeof data !== "undefined") {
        this.setState({
          content: {
            name: data.title[0].value,
            summary: data.field_resume_summary[0].value,
            introduction: data.field_resume_introduction[0].value,
            experience: data.field_resume_experience[0].value,
            description: data.field_resume_content[0].value,
            downloads: data.field_resume_downloads,
          },
        });
      }
    });
  }

  componentDidMount() {
    this.requestContent();
  }

  render() {
    return (
      <section id="resume" className="container">
        <div className="content">
          <div className="columns">
            <div className="column">
              <blockquote>
                <FontAwesomeIcon icon="terminal" size="3x" />
                <p>{this.state.content.summary.replace(/(<([^>]+)>)/gi, "")}</p>
              </blockquote>
            </div>
          </div>
          <div className="columns">
            <div className="column is-two-thirds">
              <div
                className="introduction"
                dangerouslySetInnerHTML={{
                  __html: this.state.content.introduction,
                }}
              ></div>
            </div>
            <div className="column">
              <Chart />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <h3>Experience</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.content.experience,
                }}
              ></div>
              <h3>Interests</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.content.description,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="downloads content">
          <div className="columns is-vcentered">
            <div className="column">
              <h4>
                <FontAwesomeIcon icon="download" />
                Download Resume
              </h4>
            </div>

            {this.state.content.downloads.map((val, index) => (
              <div className="column" key={index}>
                <Link to={val.uri}>
                  <FontAwesomeIcon icon={["fab", val.title]} />
                  {val.title}{" "}
                </Link>
              </div>
            ))}

            {/* <div className="column">
              <FontAwesomeIcon icon={["fab", "microsoft"]} />
              Word (900kb)
            </div>
            <div className="column">
              <FontAwesomeIcon icon="file-pdf" />
              PDF (900kb)
            </div>
            <div className="column">
              <FontAwesomeIcon icon={["fab", "google"]} />
              Google Docs
            </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
