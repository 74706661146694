import React from "react";
import ReactDOM from "react-dom";
import "bulma/css/bulma.min.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faLock,
  faCode,
  faHeart,
  faLaptopCode,
  faUser,
  faDownload,
  faFilePdf,
  faUserCircle,
  faTerminal,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route } from "react-router-dom";

library.add(
  fab,
  faLock,
  faCode,
  faHeart,
  faLaptopCode,
  faUser,
  faDownload,
  faFilePdf,
  faUserCircle,
  faTerminal,
  faSignOutAlt
);

ReactDOM.render(
  <Router>
    <Route component={App} />
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
