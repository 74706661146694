import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import "./GithubLogo.scss";

class GithubLogo extends Component {
  startAnimation() {
    let tl = anime.timeline({
      easing: "easeOutExpo",
      duration: 1500,
      direction: "alternate",
      loop: true,
      delay: 3000,
    });

    // Add children
    tl.add({
      targets: ".animated-github-logo .github-logo-path",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      delay: 250,
    });

    tl.add({
      targets: ".animated-github-logo .dot",
      opacity: 1,
      delay: function (el, i, l) {
        return i * 250;
      },
    });

    tl.add({
      targets: ".animated-github-logo .github-logo-path",
      fill: "#000",
      delay: 125,
    });

    return tl;
  }

  componentDidMount() {
    this.startAnimation().play();
  }

  render() {
    return (
      <div className="animated-github-logo">
        <svg viewBox="0 0 500 500">
          <g transform="matrix(0.98803651,0,0,0.98803651,-24.713522,-117.99298)">
            <path
              className="github-logo-path"
              d="m 275.61948,133.91154 c 135.19086,-1.05243 245.93757,106.15893 248.06112,240.1423 1.69126,106.70657 -63.35154,204.54389 -169.0352,236.04157 -8.72349,1.81738 -12.96249,-3.39652 -14.16024,-9.62064 l 0.78698,-76.37973 c 0.14002,-13.59233 -7.97757,-28.46899 -16.29693,-37.8634 66.67091,-4.68066 103.82415,-38.29328 111.72086,-105.11712 -0.5659,-25.71833 -5.09225,-62.42116 -25.08489,-81.61207 15.35029,-49.78637 -2.88794,-64.90146 -2.88794,-64.90146 -2.38659,-0.66602 -4.95482,-0.95779 -7.65051,-0.9389 h -4e-5 c -21.25619,0.1488 -50.31668,19.52804 -60.33967,26.68774 -19.58577,-5.6617 -41.01808,-8.73136 -63.45427,-8.60561 -21.53643,0.1206 -42.08395,3.17476 -60.90785,8.62018 -12.8044,-8.96054 -46.08385,-30.23039 -66.33089,-24.58007 0,0 -17.69061,14.7075 -3.50959,62.78893 -21.99474,26.13365 -27.39197,47.04767 -24.99528,76.40441 4.97093,55.07961 22.07522,95.58849 109.62689,112.78635 -7.69433,8.26641 -12.76403,18.99064 -13.98208,30.93886 l -0.008,0.0388 c 0,0 -16.80956,8.9101 -32.20217,6.54202 -15.3926,-2.36809 -29.30515,-12.72849 -34.04133,-20.42481 -4.73619,-7.69628 -20.12879,-33.74532 -42.62567,-31.96923 -22.496891,1.77606 -4.44018,12.13646 -4.44018,12.13646 0,0 21.90486,18.35272 27.82509,33.15329 5.92024,14.80061 21.49005,36.38043 48.84192,37.8895 21.46084,1.18405 27.06839,2.0931 36.43065,-3.63632 l -0.19964,51.44254 c 0,0 -0.56734,8.74311 -12.53715,6.48009 C 105.12856,576.82581 34.802141,489.19069 31.455307,381.71798 31.394588,243.88622 140.42863,134.96394 275.61948,133.91154 Z"
            />
            <path
              className="dot"
              d="m 32.870513,569.4361 a 5.7897305,3.7006524 0 0 1 5.744259,-3.70054 5.7897305,3.7006524 0 0 1 5.834488,3.64241 5.7897305,3.7006524 0 0 1 -5.652611,3.75775 5.7897305,3.7006524 0 0 1 -5.923279,-3.58338"
              transform="rotate(-17.812857)"
            />
            <path
              className="dot"
              transform="rotate(2.2035556)"
              d="m 207.69866,524.8855 a 5.0958004,3.2571094 0 0 1 5.05578,-3.25701 5.0958004,3.2571094 0 0 1 5.1352,3.20585 5.0958004,3.2571094 0 0 1 -4.97512,3.30736 5.0958004,3.2571094 0 0 1 -5.21334,-3.15389"
            />
            <path
              className="dot"
              d="m 266.53742,485.85745 a 5.4339886,3.4732709 0 0 1 5.39131,-3.47316 5.4339886,3.4732709 0 0 1 5.47599,3.41861 5.4339886,3.4732709 0 0 1 -5.30529,3.52686 5.4339886,3.4732709 0 0 1 -5.55933,-3.36321"
              transform="rotate(11.222141)"
            />
            <path
              className="dot"
              transform="rotate(43.9214)"
              d="m 466.95516,266.36468 a 5.1617522,3.2992637 0 0 1 5.12121,-3.29916 5.1617522,3.2992637 0 0 1 5.20166,3.24734 5.1617522,3.2992637 0 0 1 -5.03951,3.35017 5.1617522,3.2992637 0 0 1 -5.28081,-3.19471"
            />
            <path
              className="dot"
              d="m 478.95787,204.37503 a 4.7398491,3.0295937 0 0 1 4.70263,-3.0295 4.7398491,3.0295937 0 0 1 4.77649,2.98191 4.7398491,3.0295937 0 0 1 -4.6276,3.07634 4.7398491,3.0295937 0 0 1 -4.84918,-2.93359"
              transform="rotate(51.336564)"
            />
            <path
              className="dot"
              transform="rotate(51.336564)"
              d="m 463.52543,203.96011 a 4.4812517,2.864305 0 0 1 4.44606,-2.86421 4.4812517,2.864305 0 0 1 4.51589,2.81922 4.4812517,2.864305 0 0 1 -4.37512,2.9085 4.4812517,2.864305 0 0 1 -4.58462,-2.77354"
            />
            <path
              className="dot"
              d="m 286.37307,405.13126 a 3.893779,2.488807 0 0 1 3.86319,-2.48873 3.893779,2.488807 0 0 1 3.92388,2.44963 3.893779,2.488807 0 0 1 -3.80156,2.52721 3.893779,2.488807 0 0 1 -3.98359,-2.40994"
              transform="rotate(21.388967)"
            />
          </g>
        </svg>
      </div>
    );
  }
}

export default GithubLogo;
