import React from "react";
import "./Welcome.scss";

// import Chart from "../Chart/Chart";

const Welcome = ({ content }) => {
  return (
    <div className="container">
      <div className="columns">
        <div className="column">
          <section className="image-with-description">
            <div className="columns is-vcentered">
              <figure className="column is-one-fifth photo">
                <img src={content.photo} alt="" />
              </figure>

              <div className="column description">
                <div className="branding">
                  <h2>{content.name}</h2>
                  <h3>{content.job}</h3>
                </div>

                <div
                  className="is-size-4 has-text-centered summary"
                  dangerouslySetInnerHTML={{
                    __html: content.description,
                  }}
                ></div>
              </div>
              {/* <div className="column is-one-fifth">
                <Chart />
              </div> */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
