import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import "./CodepenLogo.scss";

class CodepenLogo extends Component {
  startAnimation() {
    const tl = anime.timeline({
      easing: "easeOutExpo",
      direction: "alternate",
      loop: true,
      delay: 3000,
    });

    tl.add({
      targets: ".animated-codepen-logo .circle-border",
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: "easeInOutSine",
      duration: 1500,
      delay: 250,
    });

    tl.add({
      targets: ".animated-codepen-logo .top-rect",
      translateY: -130,
      delay: 250,
    });

    tl.add({
      targets: ".animated-codepen-logo .pillar",
      opacity: 1,
      delay: function (el, i, l) {
        return i * 125;
      },
    });

    return tl;
  }

  componentDidMount() {
    this.startAnimation().play();
  }

  render() {
    return (
      <div className="animated-codepen-logo">
        <svg viewBox="0 0 800 800">
          <g>
            <ellipse
              className="circle-border"
              cx="401.52466"
              cy="403.06665"
              rx="326.77338"
              ry="327.70557"
            />
            <path
              className="top-rect"
              d="M 400.12666,333.74767 602.83521,469.71224 C 536.55879,517.0176 469.28638,562.89591 402.24134,609.09909 311.85461,549.53701 262.4648,512.30234 197.51247,471.11421 263.91021,423.62595 331.99943,378.68264 400.12666,333.74767 Z"
            />
            <path
              className="bottom-rect"
              d="M 400.12666,335.74767 602.83521,471.71224 C 536.55879,519.0176 469.28638,564.89591 402.24134,611.09909 311.85461,551.53701 262.4648,514.30234 197.51247,473.11421 263.91021,425.62595 331.99943,380.68264 400.12666,335.74767 Z"
            />
            <rect
              className="pillar"
              width="37.994797"
              height="136.10159"
              x="179.0372"
              y="337.1098"
              ry="7.6265269e-06"
            />
            <rect
              className="pillar"
              ry="7.6265269e-06"
              y="336.09964"
              x="583.33038"
              height="136.10159"
              width="37.994797"
            />
            <rect
              className="pillar"
              width="37.994797"
              height="136.10159"
              x="381.18753"
              y="206.81392"
              ry="7.6265269e-06"
            />
            <rect
              className="pillar"
              ry="7.6265269e-06"
              y="466.8139"
              x="381.18753"
              height="136.10159"
              width="37.994797"
            />
          </g>
        </svg>
      </div>
    );
  }
}

export default CodepenLogo;
